import {Fragment} from 'react';

import Head from 'next/head';

import Box from '@mui/material/Box';

import AuthLayout from 'src/layouts/AuthLayout';
import MainDashboard from '@/content/MainDashboard/Dashboard';

const Welcome = () => {
  return (
    <Fragment>
      <Head>
        <title>Welcome</title>
      </Head>
      <Box sx={{px: 1}}>
        <MainDashboard />
      </Box>
    </Fragment>
  );
};

export default Welcome;

Welcome.getLayout = (page: React.ReactElement) => <AuthLayout>{page}</AuthLayout>;
