import { useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { Chart } from 'src/components/Chart';

interface IProps {
  height: number
  title: string
  text: string
  data: any
  type: "area" | "line" | "bar" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "candlestick" | "boxPlot" | "radar" | "polarArea" | "rangeBar" | "rangeArea" | "treemap" | undefined
}

const ChartWidget: React.FC<IProps> = (props) => {
  const { type, title, height = 200, data, text } = props

  const theme = useTheme();

  const chartOptions: ApexOptions = {
    title: {
      text: title,
    },
    chart: {
      background: 'transparent',
      toolbar: {
        show: true,
      },
      sparkline: {
        enabled: false,
      },
    },
    theme: {
      mode: 'light',
    },
    grid: {
      padding: {
        right: 6,
        left: 6,
      },
    },
    stroke: {
      colors: [theme.colors.primary.main],
      curve: 'smooth',
      width: 2,
    },
    colors: [theme.colors.primary.main],
    markers: {
      size: 0,
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: function () {
            return title;
          },
        },
      },
      marker: {
        show: false,
      },
    },
    yaxis: {
      show: true,
    },
    xaxis: {
      categories: data.categories
    },
    legend: {
      show: true,
    },
  };
  const chartData = [
    {
      name: text,
      data: data.series
    },
  ];

  return (
    <Chart options={chartOptions} series={chartData} type={type} height={height} />
  )
}


export default ChartWidget
