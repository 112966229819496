import {Fragment} from 'react';
import {useQuery} from '@tanstack/react-query';

import {useTranslation} from 'next-i18next';

import {Avatar, Box, Card, Grid, Skeleton, styled, Typography, useTheme} from '@mui/material';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';

import quoteService, {quoteResource} from '@/services/http/QuoteService';
import orderService, {orderResource} from '@/services/http/OrderService';
import customerService, {customerResource} from '@/services/http/CustomerService';

import usePermissions from '@/hooks/usePermissions';

import Permissions from '@/components/Utilities/Permissions';
import ChartWidget from '@/components/widget/ChartWidget';
import Link from '@/components/Link';

const AvatarWrapper = styled(Avatar)(
  ({theme}) => `
        color:  ${theme.colors.alpha.trueWhite[100]};
        width: ${theme.spacing(5.5)};
        height: ${theme.spacing(5.5)};
  `
);

const MainDashboard = () => {
  const theme = useTheme();
  const {t}: {t: any} = useTranslation();

  const permission = usePermissions();

  //quotes queries
  const quotePermissionReading = permission.has(quoteResource, 'Read');

  const {data: quotesDaily} = useQuery({
    queryFn: () => quoteService.getQuotesDaily(),
    queryKey: ['quoteService.getQuotesDaily'],
    enabled: quotePermissionReading,
  });

  const {data: quotesMonthly} = useQuery({
    queryFn: () => quoteService.getQuotesMonthly(),
    queryKey: ['quoteService.getQuotesMonthly'],
    enabled: quotePermissionReading,
  });

  const {data: quotesCount} = useQuery({
    queryFn: () => quoteService.getQuotesCount(),
    queryKey: ['quoteService.getQuotesCount'],
    enabled: quotePermissionReading,
  });

  const monthlyQuotes = () => {
    if (quotesMonthly) {
      return (
        <Fragment>
          <Permissions resource={quoteResource} action='Read'>
            <Grid item xs={12}>
              <Card sx={{px: 3, pt: 2}}>
                <ChartWidget type='bar' title={t('monthlyQuotes')} text='Quotes' height={195} data={quotesMonthly} />
              </Card>
            </Grid>
          </Permissions>
        </Fragment>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Grid item xs={12}>
            {/* <Loading /> */}
            <Skeleton variant='rectangular' height={195} />
          </Grid>
        </Grid>
      );
    }
  };

  const dailyQuotes = () => {
    if (quotesDaily) {
      return (
        <Fragment>
          <Permissions resource={quoteResource} action='Read'>
            <Grid item xs={12}>
              <Card sx={{px: 3, pt: 2}}>
                <ChartWidget type='bar' title={t('dailyQuotes')} text='Quotes' height={195} data={quotesDaily} />
              </Card>
            </Grid>
          </Permissions>
        </Fragment>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Grid item xs={12}>
            {/* <Loading /> */}
            <Skeleton variant='rectangular' height={195} />
          </Grid>
        </Grid>
      );
    }
  };

  const quoteTotal = () => {
    return (
      <Permissions resource={quoteResource} action='Read'>
        <Card sx={{px: 3, mb: 2}}>
          <Box display='flex' alignItems='center' paddingTop={1}>
            <AvatarWrapper sx={{background: `${theme.colors.gradients.blue4}`}}>
              <ReceiptTwoToneIcon fontSize='medium' />
            </AvatarWrapper>
          </Box>
          <Box>
            <Typography sx={{pt: 1, fontSize: `${theme.typography.pxToRem(20)}`, fontWeight: 'bold'}} variant='subtitle2' component='div'>
              <Link href={'/quotes'}>{t('Quotes')}</Link>
            </Typography>
          </Box>
          <Box sx={{pt: 1, pb: 1}}>
            <Typography sx={{fontSize: `${theme.typography.pxToRem(25)}`}} variant='h1'>
              {quotesCount}
            </Typography>
          </Box>
        </Card>
      </Permissions>
    );
  };

  //ORDERS
  const orderPermissionRead = permission.has(orderResource, 'Read');

  const {data: ordersCount} = useQuery({
    queryFn: () => orderService.getOrdersCount(),
    queryKey: ['orderService.getOrdersCount'],
    enabled: orderPermissionRead,
  });

  const {data: ordersMonthly} = useQuery({
    queryFn: () => orderService.getOrdersMonthly(),
    queryKey: ['orderService.getOrdersMonthly'],
    enabled: orderPermissionRead,
  });

  const {data: ordersDaily} = useQuery({
    queryFn: () => orderService.getOrdersDaily(),
    queryKey: ['orderService.getOrdersDaily'],
    enabled: orderPermissionRead,
  });

  const monthlyOrders = () => {
    if (ordersMonthly) {
      return (
        <Fragment>
          <Permissions resource={orderResource} action='Read'>
            <Grid item xs={12}>
              <Card sx={{px: 3, pt: 2}}>
                <ChartWidget type='bar' title={t('monthlyOrders')} text='Orders' height={195} data={ordersMonthly} />
              </Card>
            </Grid>
          </Permissions>
        </Fragment>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Grid item xs={12}>
            {/* <Loading /> */}
            <Skeleton variant='rectangular' height={195} />
          </Grid>
        </Grid>
      );
    }
  };

  const dailyOrders = () => {
    if (ordersDaily) {
      return (
        <Fragment>
          <Permissions resource={orderResource} action='Read'>
            <Grid item xs={12}>
              <Card sx={{px: 3, pt: 2}}>
                <ChartWidget type='bar' title={t('dailyOrders')} text='Orders' height={195} data={ordersDaily} />
              </Card>
            </Grid>
          </Permissions>
        </Fragment>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Grid item xs={12}>
            {/* <Loading /> */}
            <Skeleton variant='rectangular' height={195} />
          </Grid>
        </Grid>
      );
    }
  };

  const orderTotal = () => {
    return (
      <Permissions resource={orderResource} action='Read'>
        <Card sx={{px: 3, mb: 2}}>
          <Box display='flex' alignItems='center' paddingTop={1}>
            <AvatarWrapper sx={{background: `${theme.colors.gradients.blue4}`}}>
              <ShoppingCartRoundedIcon fontSize='medium' />
            </AvatarWrapper>
          </Box>
          <Box>
            <Typography sx={{pt: 1, fontSize: `${theme.typography.pxToRem(20)}`, fontWeight: 'bold'}} variant='subtitle2' component='div'>
              <Link href={'/orders'}>{t('Orders')}</Link>
            </Typography>
          </Box>
          <Box sx={{pt: 1, pb: 1}}>
            <Typography sx={{fontSize: `${theme.typography.pxToRem(25)}`}} variant='h1'>
              {ordersCount}
            </Typography>
          </Box>
        </Card>
      </Permissions>
    );
  };

  //customers queries
  const customerPermissionRead = permission.has(customerResource, 'Read');

  const {data: customersCount} = useQuery({
    queryFn: () => customerService.getCustomersCount(),
    queryKey: ['customerService.getCustomersCount'],
    enabled: customerPermissionRead,
  });

  const customerTotal = () => {
    return (
      <Permissions resource={customerResource} action='Read'>
        <Card sx={{px: 3}}>
          <Box display='flex' alignItems='center' paddingTop={1}>
            <AvatarWrapper sx={{background: `${theme.colors.gradients.blue4}`}}>
              <PeopleAltRoundedIcon fontSize='medium' />
            </AvatarWrapper>
          </Box>
          <Box>
            <Typography sx={{pt: 1, fontSize: `${theme.typography.pxToRem(20)}`, fontWeight: 'bold'}} variant='subtitle2' component='div'>
              <Link href={'/customers'}>{t('Customers')}</Link>
            </Typography>
          </Box>
          <Box sx={{pt: 1, pb: 1}}>
            <Typography sx={{fontSize: `${theme.typography.pxToRem(25)}`}} variant='h1'>
              {customersCount}
            </Typography>
          </Box>
        </Card>
      </Permissions>
    );
  };

  return (
    <Grid container spacing={2} padding={3}>
      <Grid item xs={12} md={3} lg={3}>
        {quoteTotal()}
        {orderTotal()}
        {customerTotal()}
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container spacing={2}>
          {monthlyQuotes()}
          {monthlyOrders()}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          {dailyQuotes()}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          {dailyOrders()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainDashboard;
